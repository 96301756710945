<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'Update Policy', route: 'Admin_GDPR' },
      ]"
    />
    <div class="placeholder">
      <div class="error" v-for="error in errMsg" :key="error">{{ error }}</div>
      <div class="success" v-for="message in msg" :key="message">
        {{ message }}
      </div>
      <form v-on:submit.prevent="UpdatePolicy">
        <div class="row">
          <div class="col-12">
            <h5>GDPR Privacy Policy</h5>
            <ckeditor
              :editor="editor"
              v-model="GDPRPolicy"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5>Terms of Use</h5>
            <ckeditor
              :editor="editor"
              v-model="TermsOfUse"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="submit" class="btn btn-green" value="Save Changes" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import { useStore } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Admin_GDPR",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      msg: [],
      errMsg: [],
      GDPRPolicy: "",
      TermsOfUse: "",
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    GetPolicy() {
      this.store
        .dispatch("Admin/GetPolicies")
        .then((result) => {
          this.GDPRPolicy = result.privacy_policy;
          this.TermsOfUse = result.terms_of_use;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdatePolicy() {
      this.store
        .dispatch("Admin/UpdatePolicies", {
          Terms: this.TermsOfUse,
          Privacy: this.GDPRPolicy,
        })
        .then((result) => {
          this.msg = result.messages;
        })
        .catch((err) => {
          this.errMsg = err.messages;
        });
    },
  },
  computed: {},
  components: {
    Navigation,
  },
  mounted() {
    this.GetPolicy();
  },
};
</script>

<style>
.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
  margin-bottom: 5px;
}
.success {
  height: auto;
  width: 100%;
  background: #04a394;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #069486;
  margin-bottom: 5px;
}
</style>